import React from 'react'
import { Icon, Hover } from '.'
import { motion } from 'framer-motion'

const switchValue = (value) => value ? 0 : 1

const SliderIcon = ({ value, onChange, iconName, not, style }) => (
  <motion.div
    animate={{ color: (value && not) ? '#008800' : 'rgba(50, 50, 78, 0.5)' }}
    transition={{ duration: 0.75 }}
    style={{ cursor: 'pointer', ...style }}
    onClick={() => not ? !value && onChange(switchValue(value)) : value && onChange(switchValue(value))}
  >
    <Hover>
      <Icon posed name={iconName} size={20} />
    </Hover>
  </motion.div>
)

const ClickBall = ({ onChange, value }) => (
  <motion.div
    animate={{
      backgroundColor: value ? '#008800' : 'rgba(255, 255, 255)',
      x: value ? 100 : 0,
      y: 0
    }}
    initial={false}
    transition={{ duration: 0.75 }}
    onClick={() => onChange(switchValue(value))}
    style={{
      backgroundColor: 'white',
      position: 'relative',
      width: 20,
      height: 20,
      boxShadow: '1px 1px 2px rgba(100, 100, 100, 0.5)',
      borderRadius: 50,
      marginRight: -10,
      cursor: 'pointer'
    }}
  />
)

export const Slider = ({ value = 0, enabled = true, onChange, style }) => (
  <div
    style={{
      display: enabled ? 'flex' : 'none',
      width: '100%',
      gridArea: 'slider',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      ...style
    }}
  >
    <SliderIcon
      style={{ marginRight: 12 }}
      value={value}
      onChange={onChange}
      iconName={value === 'expired' ? 'alarm' : 'edit-2'}
    />
    <ClickBall onChange={onChange} value={value} />
    <motion.div
      transition={{ transition: { duration: 0.75 } }}
      animate={{ backgroundColor: !value ? 'rgba(50, 50, 78, 0.5)' : '#008800' }}
      onClick={() => onChange(switchValue(value))}
      style={{
        width: 100,
        height: 3,
        backgroundColor: 'transparent',
        borderRadius: 5
      }}
    />
    <SliderIcon
      not
      style={{ marginLeft: 15 }}
      value={value}
      onChange={onChange}
      iconName='sincronize-phone'
    />
  </div>
)
